import { getFrameworkServiceUrl, AuthState, fawkesUtils, getMicroAppVars } from '@sparky/framework'

// import { SparkyDem } from '../data/sparkyDefs'
// import Logger from './Logger';
import { Products } from './backend';
// import { AppAccelSettingsCommon } from '../data/queryResultsDefinition';
// import { AppAccelSettings } from './AppMgmtContext'
// import { appAccelMaxPorts } from './constants';

const Gateways = {
  'api_gateway': 'api_gateway',
  'api_gateway_global_service': 'api_gateway_global_service',
  'api_gateway_gov': 'api_gateway_gov'
} as const;

export const getAppAcclDomain = ( authState: AuthState ): string | undefined => {
  let domain: string | undefined = '';

  let region = authState.instances.getPlatformRegion();
  region = region ?? Gateways.api_gateway;
  region = (region.indexOf('gov') === -1) ? Gateways.api_gateway : Gateways.api_gateway_gov;

  domain = getFrameworkServiceUrl(region);
  return domain;
}

export const getFawkesDomain = ( authState: AuthState ): string | undefined => {
  const domain = getFrameworkServiceUrl(Gateways.api_gateway);
  return domain;
}

export const getFawkesCertsDomain = ( authState: AuthState): string | undefined => {
  return fawkesUtils.getRedirectURL();
  // return authState?.fawkes_paas_api_url 
}

export const isValidCertName = (cert: string): boolean => {
  let modCert = cert;
  
  if (cert.startsWith('*.')) {
    modCert = modCert.slice(2);
  }
  return isValidIpAddrFQDN(modCert);
}

export const isValidIpAddrFQDN = (addr: string): boolean => {
  if (isValidIPv4(addr)) {
    return true;
  } else if (isValidDomainName(addr)) {
    return true;
  }
  return false;
}

export const isValidIPv4 = (addr: string): boolean => {
    var regex = /^((25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])$/;
    return regex.test(addr);
}

const isValidDomainName = (name: string): boolean =>{
   var regex =/^((www\.([a-zA-Z0-9\-]{2,63}\.)+[a-zA-Z]{2,63})|(([a-zA-Z0-9\-]{2,63}\.)+[a-zA-Z]{2,63}))(\.[a-zA-Z]{2,63})?$/;
   return regex.test(name);
}